import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.section`
  position: relative;
  margin-bottom: 120px;
  height: 316px;
  background-color: #F7F7F7;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin-bottom: 80px;
    height: 237px;
  }
`;

const WrapperText = styled.article`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    padding: 0 20px;
  }
`;

const Title = styled.h2`
font-family: Libre Baskerville;
font-style: normal;
font-weight: bold;
font-size: 32px;
line-height: 40px;
letter-spacing: 0.16em;
color: #172C42;
margin: 0 0 12px 0;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 5px 0;
  }
`;

const Text = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 1px;
  color: black;
  margin: 0 0 58px 0;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 16px;
    margin: 0 0 20px 0;
  }
`;

const Button = styled.button`
  border: 1.5px solid #172C42;
  box-sizing: border-box;
  width: 183px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  color: #172C42;
  cursor: pointer;
  outline: none;
  margin: 0 auto;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    width: 156px;
    height: 45px;
  }

  &:hover {
    background-color: #172C42;
    color: white;
  }
`;

const BecomePartner = ({ openModal }) => (
  <Wrapper>
    <WrapperText>
      <Title>Devenez partenaire</Title>
      <Text>Une idée, un projet, un challenge ? Nous serons ravis d’en discuter.</Text>
      <Button onClick={openModal}>Nous contacter</Button>
    </WrapperText>
  </Wrapper>
);

BecomePartner.propTypes = {
  openModal: PropTypes.func.isRequired,
};

export default BecomePartner;
