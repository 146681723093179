import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors, mobileThresholdPixels, Title1 } from '../v3/styledComponents';

const Wrapper = styled.header`
  max-width: 1440px;
  text-align: center;
  margin: 0px auto 90px;
  padding: 0px 80px;
  box-sizing: border-box;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 60px;
    padding: 0px 15px;
  }
`;

const Text = styled.p`
  font-size: 18px;
  line-height: 26px;
  color: ${colors.black};
  margin: 30px auto;
  max-width: 800px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const TopBar = ({ data: { title, text } }) => (
  <Wrapper>
    <Title1>{title}</Title1>
    <Text>{text}</Text>
  </Wrapper>
);

TopBar.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
};

export default TopBar;
