import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Wrapper = styled.section`
  max-width: 1440px;
  position: relative;
  margin: 0px auto 120px auto;
  padding: 0px 80px;
  box-sizing: border-box;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin-bottom: 80px;
    padding: 0px 15px;
  }
`;

const WrapperBody = styled.section`
  display: flex;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

const Img = styled.img`
  display: block;
  width: 612px;
  max-width: 100%;
`;

const WrapperText = styled.article`
  max-width: 468px;
  padding: 250px 0px 0px 50px;
  box-sizing: border-box;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    padding: 30px 0px 0px 0px;
  }
`;

const Title = styled.h2`
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 26px;
  line-height: 35px;
  letter-spacing: 7px;
  color: ${({ theme }) => theme.colors.navy};
  margin: 0px 0px 20px 0px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 5px;
  }
`;

const Text = styled.p`
  font-size: 18px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.black};
  margin: 0px 0px 60px 0px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 20px;
    margin: 0px 0px 32px 0px;
  }
`;

const List = styled.ul`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: black;
  margin: 0px 0px 60px 0px;
  padding: 0px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 20px;
  }

  li {
    margin-left: 20px;
  }
`;

const WrapperStyledLink = styled.footer`
  text-align: center;
`;

const StyledLink = styled(Link)`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.navy};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    text-decoration: underline;
    font-size: 12px;
    letter-spacing: 2px;
  }
`;

const Advices = ({
  data: {
    img, alt, title, text, texts, link, url,
  },
}) => (
  <Wrapper>
    <WrapperBody>
      <Img src={img} alt={alt} loading="lazy" />
      <WrapperText>
        <Title>{title}</Title>
        {text
          && (
            <Text>
              {text}
            </Text>
          )}
        {texts
          && (
            <List>
              {texts.map((item) => <li key={item}>{item}</li>)}
            </List>
          )}
        <WrapperStyledLink>
          <StyledLink to={url}>{link}</StyledLink>
        </WrapperStyledLink>
      </WrapperText>
    </WrapperBody>
  </Wrapper>
);

Advices.propTypes = {
  data: PropTypes.shape({
    alt: PropTypes.string,
    img: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    texts: PropTypes.arrayOf(PropTypes.string),
    link: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

export default Advices;
