import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import withAppContext from '../../../withAppContext';

const Wrapper = styled.section`
  max-width: 1440px;
  position: relative;
  margin: 0 auto 120px auto;
  padding: 0 80px;
  box-sizing: border-box;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin-bottom: 60px;
    padding: 0 15px;
  }
`;

const WrapperBody = styled.section`
  display: flex;
  ${(props) => props.isReverse && 'flex-direction: row-reverse;'}

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

const Img = styled.img`
  display: block;
  width: 550px;
  max-width: 100%;
`;

const WrapperText = styled.article`
  padding: 100px 0px;
  max-width: 625px;
  box-sizing: border-box;
  ${(props) => props.isReverse ? 'margin-right: 80px;' : 'margin-left: 80px;'}

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin: 0px;
    padding: 30px 0 0 0;
  }
`;

const Title = styled.h2`
  position: relative;
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 26px;
  line-height: 35px;
  letter-spacing: 7px;
  color: ${({ theme }) => theme.colors.navy};
  margin: 0 0 20px 0;
  ${(props) => props.isReverse && 'text-align: right;'}

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    text-align: left;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 5px;
    margin-left: 40px;
  }

  &::before {
    ${(props) => props.isReverse && 'opacity: 0;'}
    content: '';
    width: 24px;
    border-top: 1px solid ${({ theme }) => theme.colors.black};
    position: absolute;
    top: 18px;
    left: -40px;
    @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
      top: 10px;
    }
  }

  &::after {
    ${(props) => !props.isReverse && 'opacity: 0;'}
    content: '';
    width: 24px;
    border-top: 1px solid ${({ theme }) => theme.colors.black};
    position: absolute;
    top: 18px;
    right: -40px;
    @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
      top: 10px;
    }
  }
`;

const Text = styled.p`
  font-size: 18px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.black};
  margin: 0 0 60px 0;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 40px;
  }
`;

const WrapperStyledLink = styled.footer`
  text-align: center;
`;

const StyledLink = styled(Link)`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.navy};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    text-decoration: underline;
    font-size: 12px;
    letter-spacing: 2px;
  }
`;

const OtherCollab = ({
  collab: {
    title, img, imgMobile, alt, text, url,
  }, isOdd, context: { isMobile },
}) => (
  <Wrapper>
    <WrapperBody isReverse={isOdd}>
      <Img src={isMobile && imgMobile ? imgMobile : img} alt={alt} loading="lazy" />
      <WrapperText isReverse={isOdd}>
        <Title isReverse={isOdd}>{title}</Title>
        <Text>{text}</Text>
        <WrapperStyledLink>
          <StyledLink to={url}>Découvrir la collab</StyledLink>
        </WrapperStyledLink>
      </WrapperText>
    </WrapperBody>
  </Wrapper>
);

OtherCollab.propTypes = {
  collab: PropTypes.shape({
    alt: PropTypes.string,
    img: PropTypes.string,
    imgMobile: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  isOdd: PropTypes.bool,
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

OtherCollab.defaultProps = {
  isOdd: false,
};

export default withAppContext(OtherCollab);
