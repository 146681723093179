// cSpell:ignore tilly, tili
import React from 'react';
import styled from 'styled-components';
import { Wrapper, WrapperTop, TopTitle } from './styledComponents';
import OtherCollab from './OtherCollab';
import photoBalzac from '../../../assets/sustainableFashionPage/balzac.jpg';
import photoChalmin from '../../../assets/sustainableFashionPage/chalmin.jpg';
import photoBalzacMobile from '../../../assets/sustainableFashionPage/balzac.mobile.jpg';
import photoChalminMobile from '../../../assets/sustainableFashionPage/chalmin.mobile.jpg';

const collabs = [
  {
    title: 'Balzac x Tilli',
    text: `Engagée dans une démarche “zéro gâchis”, Balzac Paris a naturellement sauté
      le pas et nous a confié les tissus de ses collections passées pour vous permettre
      de créer des accessoires uniques, sur-mesure et responsables !`,
    url: '/la-rubrique/balzac-x-tilli/',
    img: photoBalzac,
    imgMobile: photoBalzacMobile,
    alt: 'Collab Balzac avec Tili',
  },
  {
    title: 'Elise Chalmin x Tilli',
    text: `Beryl & Elise, respectivement fondatrices de Tilli & de la
      marque de vêtements Elise Chalmin lancent une collab’
      sur-mesure pour vous permettre de créer des pièces uniques… qui vous ressemblent.`,
    url: '/la-rubrique/collab-elise-chalmin/',
    img: photoChalmin,
    imgMobile: photoChalminMobile,
    alt: 'Collab Chalmin avec Tilly',
  },
];

const StyledTopTitle = styled(TopTitle)`
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    max-width: 201px;
    &::after, &::before {
      top: 26px;
    }

    &::before {
      left: -55px;
    }

    &::after {
      right: -72px;
    }
  }
`;

const OtherCollabs = () => (
  <Wrapper noBottomMargin>
    <WrapperTop>
      <StyledTopTitle>
        Les autres collabs
      </StyledTopTitle>
    </WrapperTop>
    {collabs.map((collab, index) => (
      <OtherCollab key={collab.title} collab={collab} isOdd={index % 2 === 1} />
    ))}
  </Wrapper>
);

OtherCollabs.propTypes = {
};

export default OtherCollabs;
