import React, { Component } from 'react';

import MenuBar from '../../components/home/MenuBar';
import Footer from '../../components/home/v3/Footer';
import Layout from '../../layouts/index';
import { Container, Content } from '../../components/home/homeStyledComponents';
import TopBar from '../../components/home/SustainableFashion/TopBar';
import LastCollab from '../../components/home/SustainableFashion/LastCollab';
import OtherCollabs from '../../components/home/SustainableFashion/OtherCollabs';
import BecomePartner from '../../components/home/SustainableFashion/BecomePartner';
import Advices from '../../components/home/SustainableFashion/Advices';
import PartnersPager from '../../components/home/v3/PartnersPager';
import BecomePartnerModal from '../../components/partner/BecomePartnerModal';
import InstaFeed from '../../components/home/InstaFeed';

import lastCollab from '../../assets/sustainableFashionPage/last-collab.jpg';
import partners from '../../assets/sustainableFashionPage/partners.jpg';

const topBarData = {
  title: 'Collab',
  text: `Tilli s’engage pour une mode responsable et accompagne ses marques partenaires
  avec un service de qualité et innovant, en boutique comme en ligne.
  Retouches, réparations, personnalisations, upcycling :
  des collaborations sur-mesure portées par le savoir-faire de nos artisans.`,
};

const advicesData = {
  img: partners,
  alt: 'Pourquoi devenir partenaire ?',
  title: 'Pourquoi devenir partenaire ?',
  texts: [
    `Bénéficiez d’une solution clé en main pour les retouches et
    réparations de vos clients, en boutique et sur votre site e-commerce`,
    `Donnez une seconde vie à vos collections et vos chutes de tissus.
    Découvrez toutes nos astuces pour prendre soin de votre dressing et
    remettre au goût du jour vos pièces favorites.`,
  ],
  link: 'Toutes les inspirations',
  url: '/la-rubrique',
};

class CollabPage extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
    };
  }

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  }

  openModal = () => {
    if (typeof window !== 'undefined') window.scrollTo(0, 0);

    this.setState({
      showModal: true,
    });
  }

  renderBecomePartnerModal = () => (
    <BecomePartnerModal
      closeModal={() => this.closeModal()}
    />
  );

  render() {
    const { showModal } = this.state;
    if (showModal) return this.renderBecomePartnerModal();

    return (
      <Layout routeSlug="CollabPage">
        <Container>
          <Content>
            <MenuBar />
            <TopBar data={topBarData} />
            <LastCollab imgSrc={lastCollab} />
            <OtherCollabs />
            <BecomePartner openModal={this.openModal} />
            <Advices data={advicesData} />
            <PartnersPager />
            <InstaFeed />
            <Footer />
          </Content>
        </Container>
      </Layout>
    );
  }
}

export default CollabPage;
