import styled from 'styled-components';

export const TopTitle = styled.h2`
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.16em;
  color: ${({ theme }) => theme.colors.navy};
  margin: 0px;
  text-transform: uppercase;
  position: relative;
  display: inline;

  &::after, &::before {
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    content: '';
    width: 55px;
    border: 1px solid ${({ theme }) => theme.colors.black};
    position: absolute;
    top: 18px;
  }

  &::before {
    left: -80px;
  }

  &::after {
    right: -80px;
  }

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 23px;
    line-height: 29px;

    &::after, &::before {
      width: 40px;
      top: 12px;
    }

    &::before {
      left: -55px;
    }

    &::after {
      right: -72px;
    }
  }
`;

export const Wrapper = styled.section`
  max-width: 1440px;
  padding: 120px 80px;
  box-sizing: border-box;
  margin-bottom: ${(props) => props.noBottomMargin ? 0 : 120}px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin-bottom: ${(props) => props.noBottomMargin ? 0 : 100}px;
    padding: 80px 15px;
  }
`;

export const WrapperTop = styled.header`
  margin-bottom: 80px;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin-bottom: 40px;
  }
`;

export const WrapperBody = styled.section`
  display: flex;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    flex-direction: column-reverse;
  }
`;
